export const STATUS_SUBMITTED = "pending";
export const STATUS_CERTIFICATE_APPROVED = "approved";
export const STATUS_CERTIFICATE_REJECTED = "rejected";
export const STATUS_REGISTERED = "registered";
export const STATUS_CANCELLED = "cancelled";
export const STATUS_ISSUED_OUT = "issued_out";
export const STATUS_RENEWED = "renewed";
export const STATUS_SENT_BACK = "sent_back";
export const STATUS_UPDATE_SUBMITTED = 12;
export const STATUS_DEACTIVATED = "deactivated";
export const ONTR_ACTIVE_STATUS = "1";
export const SENT_BACK = 22;
export const REVIEWED = 5;

export const PROPERTY_VALUATION_STATUS_PENDING = "Pending";
export const PROPERTY_VALUATION_STATUS_REJECTED = "Rejected";
export const PROPERTY_VALUATION_STATUS_APPROVED = "Approved";
export const PROPERTY_VALUATION_STATUS_DRAFTED = "Draft";
export const PROPERTY_VALUATION_STATUS_CERTIFIED = "Certified";
export const PROPERTY_DRAFT_VALUATION_LIST = "Draft";
export const PROPERTY_CERTIFIED_VALUATION_LIST = "Certified";

export const STATUS_UNDER_REVIEW = "under_review";
export const STATUS_PENDING = "pending";
export const STATUS_FORWARDED = "forwarded";
export const STATUS_SCHEDULED = "scheduled";
export const STATUS_REJECTED = "rejected";
export const STATUS_DEFFERED = "deferred";
export const STATUS_APPROVED = "approved";
export const STATUS_ASSESSED = "assessed";
export const STATUS_PENDING_ASSESSMENT = "pending_assessment";
export const STATUS_UNDER_SCRUTINY = "under_scrutiny";
export const STATUS_PENDING_DECISION = "pending_decision";
export const STATUS_TRADE_LICENSE_APPROVED = "APPROVED";
export const STATUS_REPORT_SUBMITTED = "report_submitted";
export const STATUS_ACCEPTED = "accepted";
export const STATUS_DENIED = "denied";
export const STATUS_REPORT_REJECTED = "report_rejected";
export const STATUS_REPORT_APPROVED = "report_approved";
export const STATUS_READY = "ready";
export const STATUS_AWAIT_COURT_HEARING = "awaiting_court_hearing";
export const STATUS_APPROVED_OBJECTIONS = "approved";
export const STATUS_REJECTED_OBJECTIONS = "rejected";
export const STATUS_WITHDRAWN_OBJECTIONS = "withdrawn";
export const STATUS_PERMIT_FORWARDED = "forwarded";
export const STATUS_VERIFIED = "verified";
export const STATUS_ACTIVE = 1;
export const STATUS_INACTIVE = 0;

export const TENDER_STATUS_PENDING = 0;
export const TENDER_STATUS_APPROVED = 1;
export const TENDERER_STATUS_REJECTED = 2;
export const TENDERER_STATUS_DEFERRED = 3;

export const COUNTER_LEVEL_ONE = 1;

//Development control statuses
export const SENT_BACK_STATUS = "sent back";
export const PENDING_STATUS = "pending";
export const REJECTED_STATUS = "rejected";
export const DEFERRED_STATUS = "deferred";
export const APPROVED_STATUS = "approved";
export const PENDING_ASSESSMENT_STATUS = "pending assessment";
export const PENDING_DECISION_STATUS = "pending decision";
export const PAID_STATUS = "paid";
export const UNSCHEDULED_STATUS = "unscheduled";
export const UNDER_INSPECTION_STATUS = "pending inspection";
export const INSPECTED_STATUS = "inspected";
export const REVIEWED_STATUS = "reviewed";
export const RECOMMENDED_STATUS = "recommended";

export const APPLICATIONS_UNDER_SCRUTINY = "Applications Under Scrutiny";

export const VALUATION_STATUS_PENDING = "pending";
export const VALUATION_STATUS_FORWARDED = "forwarded";
export const VALUATION_STATUS_SCHEDULED = "scheduled";
export const VALUATION_STATUS_REJECTED = "Rejected";
export const VALUATION_STATUS_DEFERRED = "Deferred";
export const VALUATION_STATUS_APPROVED = "Approved";
export const VALUATION_STATUS_ACCEPTED = "Accept";
export const VALUATION_STATUS_OBJECT = "Object";

export const NO_INVOICE = "no_invoice";
export const NOT_PAID = "not_paid";
export const PAID = "paid";
export const AVAILABLE = "A";
export const CANCELLED = "D";
export const PRN_PAID = "C";
export const EXPIRED = "X";
export const PARTIALLY_PAID = "partially_paid";

export const NO_LICENSE = "no_license";
export const ACTIVE = "active";

export const TYPE_EDIT = "edit";
export const TYPE_ADD = "add";

export const UPDATE_USER = "/users/update";
export const UPDATE_MARKET_STOCK_TYPES = "/market-stock-types/update";
export const CONST_REPORTS = "reports";
export const CONST_TRADE_LICENSE = "revenues/trade-licenses";
export const CONST_PROPERTY_TAX = "revenues/property-taxes";
export const CONST_COMMERCIAL_VEHICLES = "revenues/commercial-vehicles";
export const CONST_GROUND_RENT = "revenues/ground-rents";
export const CONST_MARKET_DUES = "revenues/market-dues";
export const CONST_MARKET_RENTS = "revenues/market-rents";
export const CONST_ONTR = "revenues/ontrs";
export const CONST_OPERATIONAL_PERMIT = "revenues/operational-permits";
export const CONST_PROPERTY_VALUATION = "revenues/property-valuations";
export const CONST_RENT_RATES = "revenues/rent-and-rates";
export const CONST_PRNS = "billings/prns";
export const CONST_INVOICES = "invoices";
export const CONST_PAYMENTS = "paid";
export const CONST_ASSESSMENTS = "assessments";
export const REVENUE_SOURCES_TITLE = "BUSINESS";

// CHART COLORS
export const COLOR_BLUE = "#069";
export const COLOR_GREEN = "#6aa66a";
export const COLOR_RED = "#c96253";
export const COLOR_REJECTED = "#ed8e09";
export const COLOR_FORWARDED = "#006799";

export const TRUE = true;
export const FALSE = false;

// Screens

// Configurable keys
export const BUILDING_TYPE = "Building Type";
export const COMPLETION_STATUS = "Construction Completion Status";
export const BUILDING_cONDITION = "Building Condition";
export const uSAGE = "Usage";
export const SUB_uSAGE = "Sub Usage";
export const LAND_TENURE_TYPE = "Type of Land Tenure";
export const TENDERER_VALIDITY = "Tender Validity";
export const INSTALLMENT_INTERVAL = "Installment Interval";
export const RENT_PAYMENT_INTERVALS = "rent_payment_interval";
export const BUSINESS_CATEGORIES = "rent_business_category";

export const WALL = "Walls";
export const WALL_FINISH = "Wall Finish";
export const MATERIAL_USED = "Material Used";
export const OUTDOOR_CLASSIFICATION = "classification";
export const DOORS = "Doors";
export const WINDOWS = "Windows";
export const FLOORS_FINISH = "Floor Finish";
export const ROOF_COVERINGS = "Roof covering";
export const CEILINGS = "Ceiling";
export const LAND_LORD = "kibanja_landlord";
export const PROPOSED_USAGE = "proposed_usage";
export const PROPOSED_WATER_SUPPLY_MEANS = "proposed_means_of_water_supply";
export const PROPOSED_DRAINAGE_SYSTEM = "proposed_system_of_drainage";
export const PROPOSED_WATER_SURFACE = "dev_means_of_water_disposal";
export const SOIL_WASTE_WATER_TREATMENT = "soil_waste_water_treatment";
export const SITE_BUT = "site_abut";
export const OBJECTION_REASON = "objection_reason";

export const LEASE_STATUS = "lease_status";
export const PROFESSIONAL_TYPE = "professional_type";
export const LAND_TITLE_DESCRIPTION = "title_description";
export const LEASE_DURATION = "Duration Of Lease";

export const PARKING_SPACE_AVAILABLE = "Parking Space available";
export const SECURITY_MEASURE = "Security Measures";
export const BOUNDARY_FENCES = "Boundary Fence";
export const ACCOMMODATION_CATEGORIES = "Accommodation Category";
export const RENT_TYPE = "rent_rate";
export const VALUATION_ROLL_STATUS =
  "Valuation Roll Status (Is property on roll?)";
export const PRT_STATUS = "PRT status (Does property pay PRT?)";
export const ROAD_ACCESS_TYPE = "Type of road access";
export const NEIGHBOURHOOD_STATUS = "Neighborhood Status";
export const WATER_ACCESSIBILITY = "Water accessibility";
export const POWER_SUPPLY = "Power supply";
export const SANITATION_TYPE = "Sanitation Type";
export const WASTE_FEES = "Waste Fees";
export const APPLICATION_FEES = "Application Fees";
export const SIGN_FEES = "Sky Signs";
export const RENT = "Rent";
export const RATE = "Rate";

// Users
export const USER_STAFF = "staff";
export const TAXPAYER = 2;
export const USER_ADMIN = 1;
export const SUPPER_USER = 4;

// OBJECTION DECLARATIONS

export const ONWER = 1;
export const OCCUPATION = 2;
export const OTHER = 3;

// OBJECTION TYPE OPTIONS
export const VALUATION_OBJECTOR_OWNER = "owner";
export const VALUATION_OBJECTOR_OCCUPANT = "occupant";
export const VALUATION_OBJECTOR_OTHER = "other";

//OBJECTION REASONS
export const VALUATION_OBJECTION_REASON_OWNER_DETAILS = "owner_details";
export const VALUATION_OBJECTION_REASON_ASSESSED_VALUE = "assessed_value";
export const VALUATION_OBJECTION_REASON_LOCATION_DETAILS = "location_details";
export const VALUATION_OBJECTION_REASON_USAGE = "usage";
export const VALUATION_OBJECTION_REASON_SUB_USAGE = "sub_usage";
export const VALUATION_OBJECTION_REASON_PROPERTY_DESCRIPTION =
  "property_description";
export const VALUATION_OBJECTION_REASON_PROPERTY_INCLUSION =
  "valued_property_inclusion";
export const VALUATION_OBJECTION_REASON_PROPERTY_STATEMENT =
  "statement_made_ommitted";
export const VALUATION_OBJECTION_REASON_PROPERTY_DECISION =
  "split_combine_decsion";

//OWNERSHIP TYPES
export const INDIVIDUAL = 1;
export const NON_INDIVIDUAL = 0;

export const SOURCE_YES = "Y";
export const SOURCE_NO = "N";

//OBJECTION

export const OBJECTION_NOT_ON_VALUE = "objection_not_on_value";
export const OBJECTION_ON_VALUE = "objection_on_value";

//TYPES
export const AGENT = "agent";
export const TOOL = "tool";

//NATIONALITY
export const UGANDAN = 1;
export const NON_UGANDAN = 2;

export const MARITAL_STATUS = 8;
export const GENDER = 7;
export const MAIN_ACTIVITIES = 13;
export const TITLE = 6;
//ID TYPES
export const INSTITUTION_ID = 7;
export const NATIONAL_ID = 1;
export const DRIVING_PERMIT = 2;
export const PASSPORT = 3;
export const VILLAGE_ID = 4;
export const OTHERS = 5;
export const IRAS_CIN = 6;

// REG NO TYPES
export const BRN = "brn";

//ROUTES
export const WITH_IN_DISTRICT = "route_with_in";
export const ACCROSS_DISTRICT = "route_accross";

//TENDERER TYPE
export const TENDERER_INDIVIDUAL = "i";
export const NON_TENDERER_INDIVIDUAL = "n";

///ROAD ACCESS OPTIONS

export const EXISTING_ROAD = "existing-road";
export const PLANNED_ROAD = "planned-road";

//LANDTYPE
export const LAND_TYPE_NO = 0;
export const LAND_TYPE_YES = 1;

export const ROLE_YES = 1;
export const ROLE_NO = "0";

//NO OF APPLICANTS
export const SINGLE = "single";
export const MULTIPLE = "multiple";

//HEAD OF DEPARTMENTS
export const YES = 1;
export const NO = 2;
export const EXEMPTED = 3;

//System Users
export const USERS = "users";

// DEPARTMENT
export const ROLES = "/roles";
export const UPDATE_ROLES = "/roles/update";

//GRIEVANCES
export const GRIEVANCES = "grievances";
export const UPDATE_GRIEVANCES = "/grievances/update";

//VEHICLE TAX INCOME
export const VEHICLE_TAX_INCOME = "/advanced-tax-income";
export const UPDATE_VEHICLE_TAX_INCOME = "/advanced-tax-income/update";

export const REGISTRATION_STATUSES = [
  { id: STATUS_SUBMITTED, name: "Submitted" },
  { id: STATUS_PENDING, name: "Pending" },
  { id: STATUS_APPROVED, name: "Rejected" },
  { id: STATUS_SENT_BACK, name: "Sent Back" },
];

export const PAYMENT_OPTIONS = [
  { id: NOT_PAID, name: "Not Paid" },
  { id: PAID, name: "Paid" },
];

export const FILE_TYPE_DOC = "doc";
export const FILE_TYPE_DOCX = "docx";
export const FILE_TYPE_JPG = "jpg";
export const FILE_TYPE_JPEG = "jpeg";
export const FILE_TYPE_PNG = "png";
export const FILE_TYPE_PDF = "pdf";

export const CONST_MODULES = [
  { value: CONST_TRADE_LICENSE, label: "Trade License" },
  { value: CONST_PROPERTY_TAX, label: "Property Tax" },
  { value: CONST_PROPERTY_VALUATION, label: "Property Valuation" },
  { value: CONST_COMMERCIAL_VEHICLES, label: "Commercial Road User" },
  { value: CONST_GROUND_RENT, label: "Ground Rent" },
  { value: CONST_MARKET_DUES, label: "Market Dues" },
  { value: CONST_MARKET_RENTS, label: "Market Rents" },
  { value: CONST_ONTR, label: "ONTR" },
  { value: CONST_OPERATIONAL_PERMIT, label: "Operational Permit" },
  { value: CONST_PROPERTY_VALUATION, label: "Payments" },
  { value: CONST_PRNS, label: "PRNs" },
  { value: CONST_INVOICES, label: "Invoices" },
];

const TODAY = "today";
const YESTERDAY = "yesterday";
const THIS_WEEK = "this_week";
const LAST_WEEK = "last_week";
const THIS_MONTH = "this_month";
const LAST_MONTH = "last_month";
const THIS_YEAR = "this_year";
const LAST_YEAR = "last_year";
const THIS_FY = "this_fy";
const LAST_FY = "last_fy";
export const START_DATE_FILTER = "created_date_start";
export const END_DATE_FILTER = "created_date_end";

export const preFilters = [
  TODAY,
  YESTERDAY,
  THIS_WEEK,
  LAST_WEEK,
  THIS_MONTH,
  LAST_MONTH,
  THIS_YEAR,
  LAST_YEAR,
  THIS_FY,
  LAST_FY,
];

// Document type
export const DOC_TYPE_REPORT = "report";
export const DOC_TYPE_OTHER = "other";
export const DOC_TYPE_ATTACHMENT = "attachment";

export const USER_TAXPAYER = "tax_payer";

// MARKETS MANAGEMENT
export const MARKETS = "/markets";
export const UPDATE_MARKETS = "/markets/update";

// TENERER REVENUE SOURCE ITEM MANAGEMENT
export const TENDERER_REVENUE_SOURCE_ITEM = "/tenderer-revenue-source-items";
export const UPDATE_TENDERER_REVENUE_SOURCE_ITEM =
  "/tenderer-revenue-source-items/update";

// MARKET DUE CATEGORIES MANAGEMENT
export const MARKETS_DUES_CATEGORIES = "/market-due-categories";
export const UPDATE_MARKETS_DUES_CATEGORIES = "/market-due-categories/update";

// MARKET DUE ITEMS MANAGEMENT
export const MARKET_DUES_ITEM = "/market-dues-items";
export const UPDATE_MARKET_DUES_ITEM = "/market-dues-items/update";

export const MARKETS_STOCK_TYPES = "market-stock-types";
export const UPDATE_MARKETS_STOCK_TYPES = "/market-stock-types/update";
// MARKETS DUES RATES
export const MARKETS_DUES_RATES = "/market-dues-rates";
export const UPDATE_MARKET_DUES_RATES = "/market-dues-rates/update";

// MARKET FACILITY TYPES
export const MARKET_FACILITY_TYPES = "/market-facility-types";
export const UPDATE_MARKET_FACILITY_TYPES = "/market-facility-types/update";

// MARKET FACILITY NUMBERS
export const MARKET_FACILITY_NUMBERS = "/market-facility-numbers";
export const UPDATE_MARKET_FACILITY_NUMBERS = "/market-facility-numbers/update";

// MARKET FACILITIES
export const MARKET_FACILITIES = "/market-facilities";
export const UPDATE_MARKET_FACILITIES = "/market-facilities/update";

//VEHICLE OPERATION CATEGORY
export const VEHICLE_OPERATION_CATEGORY = "/vehicle-operation-categories";
export const UPDATE_VEHICLE_OPERATION_CATEGORY =
  "/vehicle-operation-categories/update";

//VEHICLE  CATEGORY
export const VEHICLE_CATEGORY = "Vehicle Category";

//VEHICLE  PURPOSE
export const VEHICLE_PURPOSE = "Vehicle Purpose";

//VEHICLE TAX RATES
export const VEHICLE_TAX_RATES = "/vehicle-tax-rates";
export const UPDATE_VEHICLE_TAX_RATES = "/vehicle-tax-rates/update";

// PARKS MANAGEMENT
export const PARKS = "/parks";
export const UPDATE_PARKS = "/parks/update";

export const VEHICLE_CLASSIFICATION = "/vehicle-classifications";
export const UPDATE_VEHICLE_CLASSIFICATION = "/vehicle-classifications/update";

export const VEHICLE_ASSESSMENT = "/vehicle-assessments";
export const UPDATE_VEHICLE_ASSESSMENT = "/vehicle-assessments/update";

//TRADING LICENSE REGISTRATION
export const TRADINDG_LICENSE = "/trading-license";
export const UPDATE_TRADINDG_LICENSE = "/trading-license/update";

//TRADING LICENSE AMENDMENT
export const TRADINDG_LICENSE_AMENDMENT = "/trading-license-amendment";
export const TRADINDG_LICENSE_AMENDMENTS = "/trading-license-amendments";
export const UPDATE_TRADINDG_LICENSE_AMENDMENT =
  "/trading-license-amendment/update";

//RENT RATE AMENDMENT
export const RENT_AND_RATES_AMENDMENT = "/rent-and-rates-amendment";
export const RENT_AND_RATES_AMENDMENTS = "/rent-and-rates-amendments";
export const UPDATE_RENT_AND_RATES_AMENDMENT =
  "/rent-and-rates-amendment/update";

//LOCAL HOTEL TAX REGISTRATION
export const LOCAL_HOTEL_TAX = "/local-hotel-tax";
export const UPDATE_LOCAL_HOTEL_TAX = "/local-hotel-tax/update";

//LOCAL HOTEL TAX AMENDMENTS
export const LOCAL_HOTEL_TAX_AMENDMENTS = "/local-hotel-tax-amendments";
export const UPDATE_LOCAL_HOTEL_TAX_AMENDMENTS =
  "/local-hotel-tax-amendments/update";

//LOCAL SERVICE TAX REGISTRATION
export const LOCAL_SERVICE_TAX = "/local-service-tax";
export const UPDATE_LOCAL_SERVICE_TAX = "/local-service-tax/update";

//LOCAL SERVICE TAX AMENDMENT
export const LOCAL_SERVICE_TAX_AMENDMENT = "/local-service-tax-amendment";
export const UPDATE_LOCAL_SERVICE_TAX_AMENDMENT =
  "/local-service-tax-amendment/update";

//GROUND RENT REGISTRATION
export const GROUND_RENT = "/ground-rent";
export const UPDATE_GROUND_RENT = "/ground-rent/update";

//TENDERER REGISTRATION
export const TENDERER = "/tenderer";
export const UPDATE_TENDERER = "/tenderer/update";

//OUTDOOR ADVERTISING REGISTRATION
export const OUTDOOR_ADVERTISING = "/outdoor-advertising";
export const UPDATE_OUTDOOR_ADVERTISING = "/outdoor-advertising/update";

//OPERATIONAL PERMIT REGISTRATION
export const OPERRATIONAL_PERMIT = "/operational-permit";
export const UPDATE_OPERRATIONAL_PERMIT = "/operational-permit/update";

//OPERATIONAL PERMIT AMENDMENT
export const OPERRATIONAL_PERMIT_AMENDMENT = "/operational-permit-amendment";
export const UPDATE_OPERRATIONAL_PERMIT_AMENDMENT =
  "/operational-permit-amendment/update";

//MARKET RENTS REGISTRATION
export const MARKET_RENTS = "/market-rents";
export const UPDATE_MARKET_RENTS = "/market-rents/update";

//MARKET RENTS AMENDMENTS
export const MARKET_RENTS_AMENDMENT = "/market-rent-amendments";
export const MARKET_RENTS_AMENDMENTS = "/market-rent-amendment";
export const UPDATE_MARKET_RENTS_AMENDMENT = "/market-rent-amendments/update";

//COMMERCIAL ROAD USER  REGISTRATION
export const COMMERCIAL_ROAD_USER = "/park-user-fees";
export const UPDATE_COMMERCIAL_ROAD_USER = "/park-user-fees/update";

//MARKET DUES REGISTRATION
export const MARKET_DUES = "/market-dues";
export const UPDATE_MARKET_DUES = "/market-dues/update";

//PROPERTY VALUATION REGISTRATION
export const PROPERTY_VALUATION = "/property-valuation";
export const UPDATE_PROPERTY_VALUATION = "/property-valuation/update";

export const PROPERTY_VALUATION_OBJEVCTION = "/valuation-objection";

// VALUTATION APPROVERS
export const PROPERTY_VALUATION_APPROVERS =
  "accounts/users/valuation-approvers/";

// VALUTATION CREATORS
export const PROPERTY_VALUATION_CREATORS = "accounts/users/valuation-creators/";

//RENT AND RATES
export const RENT_AND_RATES = "/rent-and-rates";
export const UPDATE_RENT_AND_RATES = "/rent-and-rates/update";

//PROPERTY TAX REGISTRATION
export const PROPERTY = "/property-tax";

export const preDateFilters = [
  { value: TODAY, label: "Today" },
  { value: YESTERDAY, label: "Yesterday" },
  // { value: THIS_WEEK, label: "This Week" },
  // { value: LAST_WEEK, label: "Last Week" },
  { value: THIS_MONTH, label: "This Month" },
  { value: LAST_MONTH, label: "Last Month" },
  { value: THIS_YEAR, label: "This Year" },
  { value: LAST_YEAR, label: "Last Year" },
  { value: THIS_FY, label: "This FY" },
  { value: LAST_FY, label: "Last FY" },
];

//REVENUE SOURCES MANAGEMENT
export const UPDATE_REVENUE_CATEGORY = "/revenue-categories/update";

//DEMAND DATE
export const UPDATE_DEMAND_DATES = "/demand-date/update";

export const UPDATE_REVENUE_SOURCE_ITEMS = "/revenue-source-items/update";

// PERMISSION
export const PERMISSION = "/permissions";
export const UPDATE_PERMISSION = "/permissions/update";

//BUSINESS TRANSFER
export const BUSINESS_TRANSFER = "/business-transfers";
export const UPDATE_BUSINESS_TRANSFER = "/business-transfers/update";

//NATURE OF BUSINESS MANAGEMENT
export const UPDATE_NATURE_OF_BUSINESS = "/nature_of_business/update";

//LOCAL GOVERNMENTS MANAGEMENTS
export const UPDATE_LOCAL_GOVERNMENTS = "/local_governments/update";

//BUSINESS CATEGORIES MANAGEMENTS
export const UPDATE_BUSINESS_CATEGORIES = "/business_categories/update";

//TRADE CATEGORY MANAGEMENTS
export const UPDATE_TRADE_CATEGORY = "/trade_category/update";

//TRADE SUB CATEGORY MANAGEMENTS
export const UPDATE_TRADE_SUB_CATEGORY = "/trade_sub_category/update";

//HOTEL FACILITY RATING
export const HOTEL_FACILITY_RATING = "/hotel-facility-rating";
export const UPDATE_HOTEL_FACILITY_RATING = "/hotel-facility-rating/update";

//LHT RATES
export const LHT_RATES = "/lht-rates";
export const UPDATE_LHT_RATES = "/lht-rates/update";

//LST RATES
export const LST_RATES = "/lst-rates";
export const UPDATE_LST_RATE = "/lht-rates/update";

//LST CATEGORIES
export const LST_CATEGORIES = "/lst-categories";
export const UPDATE_LST_CATEGORIES = "/lht-categories/update";

//LAND USE
export const UPDATE_LAND_USE = "/land-use/update";
export const LAND_USE = "/land-use";

//LOCAL GOVERNMENTS MOBILE CONTACTS CONFIGURE

export const MOBILE_CONTACTS_CONFIGURE = "/mobile-contacts-configure";
export const UPDATE_MOBILE_CONTACTS_CONFIGURE =
  "/mobile-contacts-configure/update";

//WALLS
export const WALLS = "/walls";
export const UPDATE_WALLS = "/walls/update";

//TENDER VALITY
export const TENDERS_VALIDITY = "/tender-validity";
export const UPDATE_VALIDTY_TENDER = "/tender-validity/update";

//PAYMENT INTERVAL
export const PAYMENT_INTERVALS = "/payment-intervals";
export const UPDATE_PAYMENT_INTERVALS = "/payment-intervals/update";
//OBJECTIONS
export const OBJECTIONS = "/objections";
export const UPDATE_OBJECTIONS = "/objections/update";

//CONSTRUCTION COMPLETION STATUS
export const CONSTRUCTION_COMPLETION = "/construction-completion";
export const UPDATE_CONSTRUCTION_COMPLETION = "/construction-completion/update";

//NATURE OF MARKET
export const NATURE_OF_MARKET = "/nature-of-market";
export const UPDATE_NATURE_OF_MARKET = "/nature-of-market/update";

//BUILDING TYPES
export const BUILDING_TYPES = "/building-types";
export const UPDATE_BUILDING_TYPES = "/walls/update";

//TOOL TYPES
export const TOOL_TYPES = "/tool-types";
export const UPDATE_TOOL_TYPES = "/tool-types/update";

//TOOL SIZES
export const TOOL_SIZE = "/tool-sizes";
export const UPDATE_TOOL_SIZE = "/tool-sizes/update";

//OUTDOOR ADVERTISING
export const OUTDOOR_ADVERTISING_RATES = "/outdoor-rates";
export const UPDATE_OUTDOOR_ADVERTISING_RATES = "/outdoor-rates/update";

//MATERIAL USED
export const MATERIALS_USED = "/material-used";
export const UPDATE_MATERIALS_USED = "/material-used/update";

//DEPARTMENTS
export const DEPARTMENTS = "/departments";
export const UPDATE_DEPARTMENTS = "/departments/update";

//FINISH WALLS
export const FINISH_WALLS = "/finish-walls";
export const UPDATE_FINISH_WALLS = "/finish-walls/update";

//FLOOR FINISH
export const FLOOR_FINISH = "/floor-finish";
export const UPDATE_FLOOR_FINISH = "/floor-finish/update";

//FLOOR FINISH
export const ROOF_COVERING = "/roof-covering";
export const UPDATE_ROOF_COVERING = "/roof-covering/update";

//CEILING
export const CEILING = "/ceiling";
export const UPDATE_CEILING = "/ceiling/update";

//CEILING
export const TITLE_DESCRIPTION = "/title-description";
export const UPDATE_TITLE_DESCRIPTION = "/title-description/update";

//CURRENCY
export const CURRENCY = "/currencies";
export const UPDATE_CURRENCY = "/currency/update";
//BANK CODES
export const BANK_CODES = "/bank-codes";
export const UPDATE_BANK_CODES = "/bank-codes/update";

//SECURITY MEASURES
export const SECURITY_MEASURES = "/security-measures";
export const UPDATE_SECURITY_MEASURES = "/security-measures/update";

//BOUNDARY FENCE
export const BOUNDARY_FENCE = "/boundary-fence";
export const UPDATE_BOUNDARY_FENCE = "/boundary-fence/update";

//ACCOMMODATION CATEGORY
export const ACCOMMODATION_CATEGORY = "/accommodation-category";
export const UPDATE_ACCOMMODATION_CATEGORY = "/accommodation-category/update";

//MUNICIPALITY
export const UPDATE_MUNICIPALITY = "/municipalites/update";
export const MUNICIPALITIES = "/municipalites";

//PARISHES
export const UPDATE_PARISH = "/parish/update";
export const PARISHES = "/parishes";

//VILLAGES
export const UPDATE_VILLAGE = "/village/update";
export const VILLAGES = "/villages";

//DIVISIONS
export const UPDATE_DIVISION = "/division/update";
export const DIVISIONS = "/divisions";

//STREETS
export const UPDATE_STREET = "/street/update";
export const STREETS = "/streets";

//STAGES
export const UPDATE_STAGES = "/stages/update";
export const STAGES = "/stages";

//HOTEL CLASSIFICATIONS
export const UPDATE_HOTEL_CLASSIFCATIONS = "/hotel-classifications/update";
export const HOTEL_CLASSIFICATIONS = "/hotel-classifications";

//ROUTES
export const UPDATE_ROUTE = "/route/update";
export const ROUTES = "/routes";

//ROUTES
export const UPDATE_LST_NATURE_OF_BUSINESS = "/nature-of-business/update";
export const LST_NATURE_OF_BUSINESS = "/nature-of-business";

//USAGE
export const UPDATE_USAGE = "/usage/update";
export const USAGE = "/usage";

//ALL CONFIGURABLES
export const UPDATE_ALL_CONFIGURABLES = "/all-configurables/update";
export const ALL_CONFIGURABLES = "/all-configurables";

//LEASE STATUSES
export const UPDATE_LEASE_STATUS = "/lease-status/update";
export const LEASE_STATUSES = "/lease-status";

//LAND TITLE
export const UPDATE_LAND_TITLE = "/land-title/update";
export const LAND_TITLE = "/land-title";

//SUB USAGE
export const UPDATE_SUB_USAGE = "/sub-usage/update";
export const SUB_USAGE = "/sub-usage";

//REASON FOR OBJECTION
export const UPDATE_REASON_FOR_OBJECTION = "/reason-for-objection/update";
export const REASON_FOR_OBJECTION = "/reason-for-objection";

//BUILDING CONDITION
export const UPDATE_BUILDING_CONDITION = "/building-condition/update";
export const BUILDING_CONDITION = "/building-condition";

//APPLICATION FEES
export const UPDATE_APPLICATION_FEES = "/application-fees/update";
export const APPLICATION_FEEs = "/application-fees";

//WASTE FEES
export const UPDATE_WASTE_FEES = "/waste-fees/update";
export const WASTE_FEEs = "/waste-fees";

//SIGN FEES
export const UPDATE_SIGN_FEES = "/sign-fees/update";
export const SIGN_FEEs = "/sign-fees";

//ADVERTISEMENT CLASSIFICATION
export const UPDATE_ADVERTISEMENT_CLASSIFICATION =
  "/advertisement-classification/update";
export const ADVERTISEMENT_CLASSIFICATION = "/advertisement-classification";

//PROPERTY VALUATION  SCREENS
export const APPLICANT_DETAILS_SCREEN = "APPLICANT_DETAILS_SCREEN";
export const PROPERTY_LOCATION_SCREEN = "PROPERTY_LOCATION_SCREEN";
export const PROPERTY_DETAILS_SCREEN = "PROPERTY_DETAILS_SCREEN";

//COMMERCIAL ROAD USER  SCREENS
export const APPLICANT_PARK_DETAILS_SCREEN = "APPLICANT_PARK_DETAILS_SCREEN";
export const PARK_LOCATION_SCREEN = "PARK_LOCATION_SCREEN";
export const VEHICLE_DETAILS_SCREEN = "VEHICLE_DETAILS_SCREEN";
export const VEHICLE_OPERATION_DETAILS_SCREEN =
  "VEHICLE_OPERATION_DETAILS_SCREEN";

//CONDOMINIUM PERMIT  SCREENS
export const CONDOMINIUM_PERMIT_APPLICANT_DETAILS_SCREEN =
  "CONDOMINIUM_PERMIT_APPLICANT_DETAILS_SCREEN";
export const CONDOMINIUM_PERMIT_LAND_OWNER_SCREEN =
  "CONDOMINIUM_PERMIT_LAND_OWNER_SCREEN";
export const CONDOMINIUM_PERMIT_PROFESSIONALS_SCREEN =
  "CONDOMINIUM_PERMIT_PROFESSIONALS_SCREEN";
export const CONDOMINIUM_PERMIT_PROPERTY_SCREEN =
  "CONDOMINIUM_PERMIT_PROPERTY_SCREEN";
export const CONDOMINIUM_PERMIT_PROPOSED_DEVELOPMENT_SCREEN =
  "CONDOMINIUM_PERMIT_PROPOSED_DEVELOPMENT_SCREEN";
export const CONDOMINIUM_PERMIT_SUPPORTING_DOCUMENTS_SCREEN =
  "CONDOMINIUM_PERMIT_SUPPORTING_DOCUMENTS_SCREEN";
export const CONDOMINIUM_PERMIT_UNITS_SCREEN =
  "CONDOMINIUM_PERMIT_UNITS_SCREEN";

//CHAINLINK PERMIT SCREENS
export const APPLICANT_SCREEN = "APPLICANT_SCREEN";
export const APPLICATION_SCREEN = "APPLICATION_SCREEN";
export const PROFESSIONAL_SCREEN = "PROFESSIONAL_SCREEN";
export const PROPERTY_SCREEN = "PROPERTY_SCREEN";
export const TENANCY_SCREEN = "TENANCY_SCREEN";
export const DOCUMENTS_sCREEN = "DOCUMENTS_sCREEN";
//DEVELOPMENT CONTROL

//ROUTINE INSPECTION SCREENS
export const OWNER_SCREEN = "OWNER_SCREEN";
export const LOCATION_SCREEN = "LOCATION_SCREEN";
export const QUESTIONS_SCREEN = "QUESTIONS_SCREEN";

//DEVELOPMENT PERMIT
export const DEVELOPMENT_PERMIT = "/development-permit";
export const UPDATE_DEVELOPMENT_PERMIT = "/development-permit/update";

//SCRUTINY WORKFLOW
export const SCRUTINY_WORKFLOW = "/scrutiny-workflows";
export const UPDATE_SCRUTINY_WORKFLOW = "/scrutiny-workflows/update";

//SCRUTINY WORKFLOW CONFIG
export const SCRUTINY_WORKFLOW_CONFIG = "/scrutiny-workflow-config";
export const UPDATE_SCRUTINY_WORKFLOW_CONFIG =
  "/scrutiny-workflow-config/update";

//SCRUTINY WORKFLOW CONFIG ACTORS
export const SCRUTINY_SERVICE_ACTORS = "/scrutiny-service-actors";
export const UPDATE_SCRUTINY_SERVICE_ACTORS = "/scrutiny-service-actors/update";

//DEVELOPMENT CONTROL ROLES
export const DEVELOPMENT_CONTROL_ROLES = "/development-control-roles";
export const UPDATE_DEVELOPMENT_CONTROL_ROLES =
  "/development-control-roles/update";

//DEVELOPMENT CONTROL ROLES ACTORS
export const DEVELOPMENT_CONTROL_ROLES_ACTORS = "/development-control-actors/";
export const UPDATE_DEVELOPMENT_CONTROL_ROLES_ACTORS =
  "/development-control-actors/update";

//CONDONIUM PERMIT
export const CONDOMINIUM_PERMIT = "/condominium-permit";
export const UPDATE_CONDOMIMIUM_PERMIT = "/condominium-permit/update";

//SUBDIVISION CONSOLIDATION PERMIT
export const SUBDIVISION_CONSOLIDATION_PERMIT =
  "/subdivision-consolidation-permits";
export const UPDATE_SUBDIVISION_CONSOLIDATION_PERMIT =
  "/subdivision-consolidation-permits/update";

//RENOVATION PERMIT
export const RENOVATION_PERMIT = "/renovation-permit";
export const UPDATE_RENOVATION_PERMIT = "/renovation-permit/update";

//DEVELOPMENT PERMIT
export const BUILDING_PERMIT = "/building-permit";
export const UPDATE_BUILDING_PERMIT = "/building-permit/update";

//CHAINLINK PERMIT
export const CHAINLINK_PERMIT = "/chainlink-permit";
export const UPDATE_CHAINLINK_PERMIT = "/chainlink-permit/update";

//HOARDING PERMIT
export const HOARDING_PERMIT = "/hoarding-permit";
export const UPDATE_HOARDING_PERMIT = "/hoarding-permit/update";

//EXCAVATION PERMIT
export const EXCAVATION_PERMIT = "/excavation-permit";
export const UPDATE_EXCAVATION_PERMIT = "/excavation-permit/update";

//OUTDOOR ADVERTISEMENT PERMIT
export const OUTDOOR_ADVERTISEMENT_PERMIT = "/outdoor-advertisement-permit";
export const UPDATE_OUTDOOR_ADVERTISEMENT_PERMIT =
  "/outdoor-advertisement-permit/update";

//OCCUPATIONAL PERMIT
export const OCCUPATIONAL_PERMIT = "/occupational-permit";
export const UPDATE_OCCUPATIONAL_PERMIT = "/occupational-permit/update";

//DEMOLITION PERMIT
export const DEMOLITION_PERMIT = "/demolition-permit";
export const UPDATE_DEMOLITION_PERMIT = "/demolition-permit/update";

//LEASE-APPLICATION
export const LEASE_APPLICATION = "/lease-application";
export const UPDATE_LEASE_APPLICATION = "/lease-application/update";

//PERMIT PROFESSIONALS
export const PERMIT_PROFESSIONALS = "/professionals";
export const UPDATE_PERMIT_PROFESSIONALS = "/professionals/update";

//REVENUE BUDGET
export const REVENUE_BUDGET = "/revenue-budget";
export const UPDATE_REVENUE_BUDGET = "/revenue-budget/update";

//ONTR
export const ONTR = "/ontrs";
export const UPDATE_ONTR = "/ontrs/update";

//ONTR
export const BUSINESS_CATEGORY = "/business-category";
export const UPDATE_BUSINESS_CATEGORY = "/business-category/update";

//RENT PAYMENT INTERVAL
export const RENT_PAYMENT_INTERVAL = "/rent-payment-interval";
export const UPDATE_RENT_PAYMENT_INTERVAL = "/rent-payment-interval/update";

//DEVELOPMENT PERMIT SCREENS
export const DEVELOPMENT_CONTROL_SCREEN_ONE = "DEVELOPMENT_CONTROL_SCREEN_ONE";
export const DEVELOPMENT_CONTROL_SCREEN_TWO = "DEVELOPMENT_CONTROL_SCREEN_TWO";
export const DEVELOPMENT_CONTROL_SCREEN_THREE =
  "DEVELOPMENT_CONTROL_SCREEN_THREE";
export const DEVELOPMENT_CONTROL_SCREEN_FOUR =
  "DEVELOPMENT_CONTROL_SCREEN_FOUR";
export const DEVELOPMENT_CONTROL_SCREEN_FIVE =
  "DEVELOPMENT_CONTROL_SCREEN_FIVE";
export const DEVELOPMENT_CONTROL_SCREEN_SIX = "DEVELOPMENT_CONTROL_SCREEN_SIX";
export const DEVELOPMENT_CONTROL_SCREEN_SEVEN =
  "DEVELOPMENT_CONTROL_SCREEN_SEVEN";
export const URL_PERMIT_QUESTION = "permit-questions";
export const URL_SITE_INSPECTION_QUESTION = "site-inspection-questions";
export const URL_SITE_INSPECTION_ANSWER = "site-inspection-answers";

// ROLES
export const CAN_ADMIN = "can_admin";
export const CAN_DASHBOARD = "can_dashboard";
export const CAN_MUNICIPALITY = "can_municipality";
export const CAN_DIVISION = "can_division";
export const CAN_PARISH = "can_parish";
export const CAN_PUBLIC = "can_public";
export const CAN_MARKET_ADMIN = "can_market_admin";
export const CAN_PHYSICAL_PLANNING = "can_physical_planning";
export const CAN_PHYSICAL_PLANNING_DIVISION = "can_physical_planning_division";
export const CAN_PROFESSIONAL = "can_professional";

export const APP_CAN_APPROVE = "app_can_approve";
export const APP_CAN_ADMIN = 1;

// Role Names
export const APP_AUTHORITY = "Approving Authority";
export const PUBLIC_APP_USER = "Public App User";
export const VALUATION_APPROVER = "Valuation Approver";
export const MIS_AUTHORITY = "MIS Authority";
export const AUDIT_AUTHORITY = "Audit Authority";
export const VALUATION_ = "Valuation Approver";
export const VALUATION_DATA_ENTRANT = "Valuation Data Entrant";
export const VALUATION_DATA_ENTRY_AUTHORITY = "Valuation Data Entry Authority";
export const VALUATION_COURT_CLERK = "Valuation Court Clerk";
export const MUN_APPROVER = "Municipality Approver";
export const MARKET_ADMIN = "Market Admin";
export const PARISH_ADMIN = "Parish Admin";
export const DIVISION_ADMIN = "Division Admin";
export const VERIFICATION_AUTHORITY = "Verification Authority";
export const SUPERVISOR_TRT = "Supervisor TRT";

export const PROFESSIONAL = "Professional";

// roles dev_control
export const FRONT_DESK_OFFICER = "Front Desk Officer";
export const SECRETARY_PHYSICAL_PLANNING_COMMITTEE =
  "Secretary Physical Planning Committee";
export const SUPERVISIOR_DEV_CONTROL = "Supervisor Development Control";
export const LAND_SURVEYOR = "Land Surveyor";
export const PHYSICAL_PLANNER = "Physical Planner";
export const ADMIN = "Admin";
export const DATA_ENTRANT = "Data Entrant";
export const PUBLIC_HEALTH_OFFICER = "Public Health Officer";
export const STRUCTURAL_ENGINEER = "Structural Engineer";
export const ENVIRONMENTAL_OFFICER = "Environmental Officer";
export const SITE_INSPECTION_OFFICER = "Site Inspection Officer";

// Report Summary
export const STATUS_SUMMARY = 1;
export const STATUS_REVENUE_FORECAST = 2;
export const STATUS_BUDGET_PERFORMANCE = 7;

// Development Control
export const STATUS_DEVELOPMENT_CONTROL = 3;
export const STATUS_REVENUE_SOURCE = 4;
export const STATUS_SERVICE_APPLICATION = 5;
export const STATUS_GRIEVANCE = 6;
export const STATUS_SUMMARY_FYS = "fys";

export const tenderertype = [
  {
    id: TENDERER_INDIVIDUAL,
    name: "Individual",
  },
  {
    id: NON_TENDERER_INDIVIDUAL,
    name: "Non-Individual",
  },
];

// ROUTINE INSPECTION
export const UPDATE_ROUTINE_INSPECTION = "/routine-inspection/update/";

// ENFORCEMENT
export const UPDATE_ENFORCEMENT = "/enforcements/update/";

// PERMIT FEES
export const UPDATE_PERMIT_FEES = "/permit-fees/update/";

// PERMIT FEES
export const UPDATE_ALL_PERMIT_FEES = "/all-permit-fees/update/";

// SELF EMPLOYMENT RATES
export const UPDATE_SELF_EMPLOYMENT_RATES = "/self-employment-rates/update/";

// ANNUAL RATES
export const UPDATE_ANNUAL_RATES = "/annual-rates/update/";

// PLANNING RATES
export const UPDATE_PLANNING_RATES = "/planning-rates/update/";

// ESCALATION PERIODS
export const UPDATE_ESCALATION_PERIODS = "/escalation-periods/update/";

// LETTABLE AREA PERCENTAGES
export const UPDATE_AREA_PRECENTAGE = "/lettable-area-percentages/update/";

// RATEABLE PERCENTAGES
export const UPDATE_RATEABLE_PERCENTAGE = "/rateable-value-percentages/update/";

// GROSS VALUE PERCENTAGES
export const UPDATE_GROSS_VALUE_PERCENTAGE = "/gross-value-percentages/update/";

// VALUATION PERIODS
export const UPDATE_VALUATION_PERIODS = "/valuation-periods/update/";

export const MUN_NAME = "municipality_name";
export const DIV_NAME = "division_name";
export const PAR_NAME = "parish_name";
export const VILL_NAME = "village_name";
export const STR_NAME = "street_name";

export const ONTR_EXTERNAL = "ontr_external";

// STRING FORMATS
export const DATE_TIME_FORMAT = "YYYY-MM-DD, HH:mm A";
export const DATE_FORMAT = "YYYY-MM-DD";

// ONTR REGISTER
export const ONTR_REGISTER = "/ontrs/1";

// OLD_VALUATION_ROLL
export const OLD_VALUATION_ROLL = "old-valuation-roll";
