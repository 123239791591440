import {Bar, Pie} from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement, Chart,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { configColor, configSecondary } from "../../styled/Theme";
import { alpha } from "@mui/material";
import { scaleFormatter } from "../../../utils/formatters";
import { removeEmpty } from "../../../utils/Filters";
import RadioGroup from "../../common/radio/RadioGroup";
import styled from "styled-components";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement
);

export const StatusChart = ({ graph }) => {
  const [dataKey, setDataKey] = useState("collections");
  /* NOTE: Data displayed on the graph is filtered out to 
  remove all irrelevant fields with 0 value */
  const data = {
    labels: removeEmpty(graph, dataKey).map((val) => val.name),
    datasets: [
      {
        label: `${dataKey}: UGX`,
        data: removeEmpty(graph, dataKey).map((val) => val[dataKey]),
        backgroundColor: [alpha(configColor, 0.5), alpha(configSecondary, 0.5)],
        borderColor: [configColor, configSecondary],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        ticks: {
          callback: function (label) {
            return scaleFormatter(label);
          },
        },
        beginAtZero: true,
      },
    },
    legend: {
      labels: {
        fontSize: 40,
      },
    },
  };

  const radioOptions = [
    { value: "collections", label: "Collections" },
    { value: "assessments", label: "Assessments" },
    { value: "submissions", label: "Submissions" },
  ];
  
  return (
    <div style={{ width: "100%", paddingTop: 10 }}>
      <div className="flexCenter">
        <RadioGroup options={radioOptions} onChange={setDataKey} />
      </div>
      <Wrapper>
        <Bar data={data} height={200} options={options} />
      </Wrapper>
    </div>
  );
};

export const StatusPieChart = ({ data , labels}) => {
  Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels);
  const total = data?.reduce((acc, val) => acc + val, 0);

  const formater =  (value) => {
    const percentage = ((value / total) * 100).toFixed(0);
    return value > 0 ? `${percentage}%` : '';
  };

  const pieChartData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"],
        hoverBackgroundColor: ["#0077E0", "#00B28A", "#E5A800", "#E56722"],
      },
    ],
  };
  const options = {
    responsive: true,
    interaction: {
      mode: 'none',
    },
    plugins: {
      legend: {
        position: "bottom",
      },
      datalabels: {
        display: true,
        formatter: (value) => formater(value),
        color: '#fff',
        font: {
          weight: 'bold',
          size: 20,
        },
        anchor: 'center',
        align: 'center',
      },
    },
  };
  return (
      <div style={{ width: "100%", paddingTop: 10 }}>
        <Pie data={pieChartData} options={options} />;
      </div>
  )
}

const Wrapper = styled.div`
  height: 94%;
  width: 100%;
  padding: 10px;
  @media (max-width: 960px) {
    max-height: 400px;
  }
`;
